import React from "react"
import Seo from "../components/seo"
import { injectIntl } from "react-intl"

const WirePage = ({ children, intl }) => {
    return (
        <>
            <Seo id="wire" description={
                intl.formatMessage({ id: "page.wire.description" })} keywords={
                intl.formatMessage({ id: "page.wire.keywords" })} />
            {children}
        </>
    )
}

export default injectIntl(WirePage)
