import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import slugify from "react-slugify"

const Cover = ({ eyebrow, title, imageName, children, align, ...other }) => {
  const { images } = useStaticQuery(
    graphql`
      query CoverImagesQuery {
        images: allFile(filter: { relativePath: { glob: "covers/*.jpg" } }) {
          edges {
            node {
              name
              childImageSharp {
                gatsbyImageData(quality: 85, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    `
  )

  let image = false

  if (!imageName) imageName = slugify(eyebrow)

  image = images.edges.find(image => imageName === image.node.name)

  return (
    <section className="bg-black text-white max-w-large-screen mx-auto relative">
      <div className="lg:pt-16/9 lg:h-0 lg:overflow-hidden lg:relative">
        {image && (
          <figure className="lg:absolute lg:left-0 lg:top-0 lg:w-full lg:h-full">
            <GatsbyImage
              image={image.node.childImageSharp.gatsbyImageData}
              alt={title}
            />
          </figure>
        )}
      </div>
      <div
        className={`lg:absolute lg:inset-0 lg:z-20 ${
          align === "right" ? "lg:gradient-right" : "lg:gradient-left"
        }`}
      >
        <div className="container xl:max-w-wide">
          <div
            className={`cover-content col py-md text-sm md:text-base lg:pt-lg lg:text-sm lg:w-2/3 xl:text-base xl:w-1/2 ${
              align === "right" ? "lg:ml-auto lg:mr-0" : ""
            }`}
          >
            {eyebrow && <h3 className="section-heading">{eyebrow}</h3>}
            <h2 className="text-parent mt-md">{title}</h2>
            {children}
          </div>
        </div>
      </div>
    </section>
  )
}

Cover.defaultProps = {
  align: `left`,
}

export default Cover
