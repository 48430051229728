import React from "react"
import Layout from "../../layouts/en"
import WirePageBase from "../../templates/wire"
import Intro from "../../components/intro"
import Link from "../../components/link"
import Cover from "../../components/cover"
import Illustration from "../../components/illustration"

export default () => {
    return (
        <Layout>
            <WirePageBase locale="en">
                <Intro title="Highest quality wire made by Finkernagel">
                    <p>Innovative wire production and a broad spectrum of possible delivery forms are what make Finkernagel a high-performance wire mill. Our wire withstands the most demanding of cold-forming processes. Our extensive test equipment, e.g. wire surface testing, guarantees the very highest quality. We take our role as problem solver and project support seriously, and with our just-in-time logistics we aim to fulfil every customer requirement as fast as possible.</p>
                    <Link to="/wire/delivery-program" className="accessory">To our delivery programme</Link>
                </Intro>
                <Cover eyebrow="Wire rod store" imageName="walzdrahtlager" title="Quality from the very start">
                    <p>We buy our wire rod from qualified steel and rolling mills. A well-organised raw material warehouse with more than 5,000 m² storage area ensures a high supply capability. The wire rod is stored carefully in defined areas.</p>
                    <p>Our efficient material economy and careful handling during storage and removal ensures flawless quality of the material used.</p>
                </Cover>
                <Cover eyebrow="Pickling and coating plant" imageName="beiz--und-beschichtungsanlage" title="Huge range of high-performance surfaces" align="right">
                    <p>The material is cleaned and coated fully automatically in our 18 large-scale treatment baths. Spent media is automatically replenished as required. Treatment results are thus constant and reproducible.</p>
                    <p>Our comprehensive process visualisation provides seamless documentation and tracking of the whole process flow.</p>
                </Cover>
                <Cover eyebrow="Wire drawing" imageName="drahtzug" title="Our drawing plants are the basis of our quality">
                    <p>On 16 PLC-controlled drawing machines, we produce wire in the closest tolerances in dimensions ranging from Ø 1.00 mm to 33.00 mm.</p>
                    <p>All drawing machines are linked to our MDE/BDE system so that up-to-date production situations can be evaluated and deviations can be immediately analysed and remedied using appropriate measures. This means that we have reproducible basic process data for every individual coil.</p>
                </Cover>
                <Cover eyebrow="Heat treatment" imageName="warmebehandlung" title="Optimum annealed microstructure for outstanding cold heading results" align="right">
                    <p>On four bases in our bell annealing furnaces, wire rod and pre-drawn wire with batch sizes up to 50 tons is annealed according to pre-programmed routines.  The use of hydrogen as inert gas results in the cleanest of wire surfaces, a homogeneously annealed microstructure and equally good mechanical properties.</p>
                </Cover>
                <Cover eyebrow="Quality management" imageName="qualitatsmanagement" title="Implementation of demanding requirements">
                    <p>Continuous wire surface testing (DE: <strong>DR</strong>aht-<strong>O</strong>berflächen-<strong>P</strong>rüfung DROP) provides insights into our own and into base material process stability.</p>

                    <p>We are certified according to EN ISO 9001 and IATF 16949.</p>

                    <Link to="/downloads">Certificate download</Link>
                </Cover>
                <Cover eyebrow="Logistics" imageName="logistik" title="Ready for your production anytime – just-in-time" align="right">
                    <p>Once produced, wires are packed, stored and promptly delivered by our logistics centre according to our customers’ specifications.</p>

                    <p>The material handling system is designed to treat the wire as gently as possible and to ensure safe dispatch. Transverse, longitudinal or container loading is all carried out under a roof. Supply documents are provided electronically on request.</p>
                </Cover>

                <section className="section">
                    <header className="container">
                        <div className="col md:ml-auto md:mr-0 md:w-1/2 lg:w-1/3">
                            <h3 className="section-heading">The production process at a glance</h3>
                        </div>
                    </header>
                    <Illustration imageName="beschichten">
                        <h2 className="text-black">Pickling and coating</h2>
                        <p>Wire rod is de-scaled, cleaned and coated.</p>
                    </Illustration>
                    <Illustration imageName="drahtziehen">
                        <h2 className="text-black">Wire drawing</h2>
                        <p>The coated wire is drawn through one or more drawing dies to bring it down to a smaller diameter.</p>
                    </Illustration>
                    <Illustration imageName="gluehen">
                        <h2 className="text-black">Annealing</h2>
                        <p>At temperatures up to 800 °C the wire, which has been hardened through drawing, is softened again, ready for forming in further processing stages.</p>
                    </Illustration>
                </section>
            </WirePageBase>
        </Layout>
    )
}
