import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const Illustration = ({ imageName, alt, children, ...other }) => {
  const { images } = useStaticQuery(
    graphql`
      query ImagesQuery {
        images: allFile(
          filter: { relativePath: { glob: "illustrations/*.png" } }
        ) {
          edges {
            node {
              name
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    `
  )

  let image = false

  if (imageName)
    image = images.edges.find(image => imageName === image.node.name)

  return (
    <div className="container mb-md md:flex md:items-center" {...other}>
      <figure className="col md:w-1/2 lg:w-2/3">
        {image && (
          <GatsbyImage
            image={image.node.childImageSharp.gatsbyImageData}
            alt={alt}
          />
        )}
      </figure>
      <div className="col md:w-1/2 lg:w-1/3">{children}</div>
    </div>
  )
}

export default Illustration
